/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiStarLine } from "react-icons/ri"

const PulseSurveyTemplateDownloadPage = () => {
  
  // List of pulse survey questions by category
  const pulseQuestions = [
    "How satisfied are you with your work this week?",
    "Do you feel you had the resources you needed to do your job well this week?",
    "Did you receive meaningful recognition for your work this week?",
    "Was your workload manageable this week?",
    "Did you have clear priorities and direction this week?",
    "How well did your team collaborate this week?",
    "How supported did you feel by your manager this week?",
    "How energized do you feel about work heading into next week?",
    "What was your biggest achievement this week?",
    "What was your biggest challenge this week?"
  ]
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Weekly Pulse Survey Template | Quick Employee Feedback Tool"
        description="Simple, effective weekly pulse survey template to regularly track employee sentiment and engagement. Identify trends quickly with this 5-minute feedback tool."
        keywords={[
          "employee pulse survey",
          "weekly feedback template",
          "quick engagement assessment",
          "team sentiment tracker",
          "employee mood meter",
          "regular feedback tool",
          "workplace pulse check",
          "team morale survey",
          "continuous feedback system",
          "employee check-in template"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/employee-engagement" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Employee Engagement Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Weekly Pulse Survey Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A quick, effective pulse survey to regularly track employee sentiment. 
              Takes just 5 minutes to complete and provides valuable insights into 
              team morale, engagement, and potential issues needing attention.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <a 
                href="/templates/weekly-pulse-survey.pdf" 
                download
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Download PDF
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* Template Preview Section */}
      <div sx={{
        bg: 'white',
        py: [4, 5]
      }}>
        <div sx={{
          maxWidth: '900px',
          mx: 'auto',
          px: 3,
        }}>
          <div className="printable-template" sx={{
            border: '1px solid',
            borderColor: 'rgba(0,0,0,0.1)',
            borderRadius: '8px',
            p: [3, 4],
            mb: 5,
            boxShadow: '0 2px 15px rgba(0,0,0,0.05)',
            '@media print': {
              border: 'none',
              boxShadow: 'none',
              p: 0
            }
          }}>
            {/* Template Header */}
            <div sx={{
              borderBottom: '2px solid',
              borderColor: 'primary',
              pb: 3,
              mb: 4,
              '@media print': {
                pb: 2,
                mb: 3
              }
            }}>
              <h2 sx={{
                fontSize: ['1.5rem', '1.8rem'],
                fontWeight: 700,
                color: 'primary',
                textAlign: 'center',
                mb: 2
              }}>
                Weekly Pulse Survey
              </h2>
              
              <p sx={{
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8,
                textAlign: 'center',
                mb: 3
              }}>
                This quick survey helps us understand how you're feeling about work this week.
                Your feedback is confidential and helps us make continuous improvements.
              </p>
              
              <div sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: 2,
                borderTop: '1px solid',
                borderColor: 'rgba(0,0,0,0.1)',
                pt: 2,
                fontSize: '0.8rem',
                color: 'text',
                '@media print': {
                  pt: 1
                }
              }}>
                <div>
                  <strong>Team:</strong> ____________________
                </div>
                <div>
                  <strong>Week of:</strong> ____________________
                </div>
                <div>
                  <strong>Submitted:</strong> ____________________
                </div>
              </div>
            </div>
            
            {/* Pulse Rating Questions */}
            <div sx={{
              mb: 4,
              '@media print': {
                mb: 3
              }
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 600,
                color: 'primary',
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiStarLine /> Weekly Check-In
              </h3>
              
              <p sx={{
                fontSize: '0.9rem',
                mb: 4,
                color: 'text'
              }}>
                For each statement, select a number from 1 (strongly disagree) to 5 (strongly agree).
              </p>
              
              {pulseQuestions.slice(0, 8).map((question, index) => (
                <div 
                  key={`question-${index}`}
                  sx={{
                    borderBottom: index < pulseQuestions.length - 1 ? '1px solid' : 'none',
                    borderColor: 'rgba(0,0,0,0.1)',
                    py: 3,
                    '@media print': {
                      py: 2
                    }
                  }}
                >
                  <div sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2
                  }}>
                    <p sx={{
                      fontSize: '0.95rem',
                      fontWeight: 500,
                      mb: 0
                    }}>
                      {question}
                    </p>
                  </div>
                  
                  <div sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mx: [0, 4]
                  }}>
                    <div sx={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
                      <span>Strongly Disagree</span>
                    </div>
                    
                    <div sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: [2, 3],
                      my: 2
                    }}>
                      {[1, 2, 3, 4, 5].map(rating => (
                        <div 
                          key={`q${index}-r${rating}`}
                          sx={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            border: '1px solid',
                            borderColor: 'rgba(0,0,0,0.3)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.8rem',
                            '@media print': {
                              width: '25px',
                              height: '25px'
                            }
                          }}
                        >
                          {rating}
                        </div>
                      ))}
                    </div>
                    
                    <div sx={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
                      <span>Strongly Agree</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Open-ended Questions */}
            <div sx={{
              mb: 4,
              '@media print': {
                mb: 3,
                pageBreakInside: 'avoid'
              }
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Additional Insights
              </h3>
              
              <div sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4
              }}>
                {pulseQuestions.slice(8).map((question, index) => (
                  <div key={`open-q-${index}`}>
                    <label 
                      htmlFor={`open-q-${index}`}
                      sx={{ 
                        display: 'block', 
                        fontSize: '0.95rem', 
                        fontWeight: 500,
                        mb: 2
                      }}
                    >
                      {question}
                    </label>
                    <div 
                      id={`open-q-${index}`}
                      sx={{
                        border: '1px solid',
                        borderColor: 'rgba(0,0,0,0.2)',
                        borderRadius: '4px',
                        height: '80px'
                      }} 
                    />
                  </div>
                ))}
                
                <div>
                  <label 
                    htmlFor="additional-comments"
                    sx={{ 
                      display: 'block', 
                      fontSize: '0.95rem', 
                      fontWeight: 500,
                      mb: 2
                    }}
                  >
                    Is there anything else you'd like to share about your experience this week?
                  </label>
                  <div 
                    id="additional-comments"
                    sx={{
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px',
                      height: '80px'
                    }} 
                  />
                </div>
              </div>
            </div>
            
            {/* Footer */}
            <div sx={{
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)',
              textAlign: 'center',
              fontSize: '0.8rem',
              color: 'text',
              opacity: 0.7,
              '@media print': {
                mt: 3,
                pt: 2
              }
            }}>
              Thank you for your feedback! Your input helps us create a better work environment.
            </div>
          </div>
          
          <div sx={{
            display: ['block', 'flex'],
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media print': {
              display: 'none'
            }
          }}>
            <div sx={{
              mb: [3, 0]
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                color: 'text',
                mb: 2
              }}>
                Tips for Using Pulse Surveys
              </h3>
              
              <ul sx={{
                pl: 3,
                fontSize: '0.95rem'
              }}>
                <li sx={{ mb: 1 }}>Conduct consistently (weekly or bi-weekly)</li>
                <li sx={{ mb: 1 }}>Keep surveys anonymous to encourage honest feedback</li>
                <li sx={{ mb: 1 }}>Analyze trend data rather than focusing on individual responses</li>
                <li sx={{ mb: 1 }}>Act quickly on urgent issues identified in feedback</li>
                <li sx={{ mb: 1 }}>Share insights and actions taken with your team</li>
              </ul>
            </div>
            
            <div sx={{
              textAlign: ['left', 'right']
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease',
                  mb: 2,
                  '&:hover': {
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <p sx={{
                fontSize: '0.8rem',
                color: 'text',
                opacity: 0.7
              }}>
                For more HR templates and tools, <Link to="/guides/employee-engagement" sx={{ color: 'primary' }}>visit our guide</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PulseSurveyTemplateDownloadPage 